<template>
  <div class="main-container">
    <Header></Header>
    <div class="contents">
      <Discuss></Discuss>
      <Round></Round>
      <Card></Card>
      <Auther></Auther>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../home/components/Footer.vue";
import Header from "./view/Header.vue";
import Discuss from "./view/Discuss.vue";
import Round from "./view/Round.vue";
import Card from "./view/Card.vue";
import Auther from "./view/Auther.vue";

export default {
  components: {
    Footer,
    Header,
    Discuss,
    Round,
    Card,
    Auther,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1500px) {
  .contents {
    min-height: 1000px;
    width: 1440px;
    margin: 80px auto;
    overflow: hidden;
  }
}

.main-container {
  .contents {
    min-height: 1000px;
    width: 1440px;
    margin: 80px auto;
  }
}
</style>
