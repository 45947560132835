<template>
  <div class="list-container">
    <div class="container">
      <ul class="nav">
        <li
          v-for="(item, i) in navList"
          :key="i"
          :class="{ active: i === index }"
          @click="handleClick(i, item)"
        >
          {{ item.fieldName }}
        </li>
        <span class="more" @click="goMore">更多 &gt;</span>
      </ul>
      <div style="position: relative;">
        <div class="list-main" v-if="blockList.length > 0">
          <Block v-for="(item, i) in blockList" :key="i" :block="item"></Block>
        </div>
        <div class="no-student" v-else>
          <img :src="require('@/assets/qietu/results.png')" alt="">
          <p>暂无学者</p>
        </div>
        <Spin size="large" fix v-if="blockListSpin"></Spin>
      </div>
      <div class="more-box" >
        <div class="more-btn" @click="goMore">点击查看更多学者</div>
      </div>
    </div>
    <div class="side">
      <div class="xsq"></div>
      <div class="bg1"></div>
      <div class="bg2"></div>
    </div>
    <BackTop :bottom="185" :right="50">
      <div class="toTop">
        <Icon type="ios-arrow-up" />
      </div>
    </BackTop>
  </div>
</template>

<script>
import Block from "../components/Block.vue";
export default {
  components: {
    Block,
  },
  data() {
    return {
      navList: [],
      index: 0,
      blockList: [],
      blockListSpin: false
    };
  },
  created() {
    this.getFieldRecommend();
  },
  methods: {
    async getFieldRecommend() {
      const resp = await this.$apis.autherServe.getFieldRecommend();
      this.navList = resp.data;
      this.getAutherList({
        fieldId: resp.data[0].fieldId,
        pageNo: 1,
        pageSize: 10,
      });
    },
    async getAutherList(data) {
      this.blockListSpin = true
      const resp = await this.$apis.autherServe.getAutherList(data);
      this.blockListSpin = false
      this.blockList = resp.data.list;
    },
    handleClick(i, item) {
      this.index = i;
      this.getAutherList({
        fieldId: item.fieldId,
        pageNo: 1,
        pageSize: 10,
      });
    },
    goMore() {
      let url = this.$router.resolve("/moreAuther");
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  width: 1440px;
  min-height: 650px;
  margin-top: 100px;
  display: flex;
  .container {
    height: 700px;
    .nav {
      width: 1325px;
      height: 28px;
      display: flex;
      // margin: 0 auto;
      li,
      .more {
        width: 160px;
        text-align: center;
        font-size: 18px;
        color: #062233;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        height: 40px;
        text-overflow: ellipsis;
      }
      li.active {
        &::after {
          content: "";
          display: block;
          width: 160px;
          height: 8px;
          background-image: url("~@/assets/imgs/xz.png");
          background-size: 100% 100%;
        }
      }
      .more {
        color: #00a4ff;
      }
    }
    .list-main {
      margin-top: 50px;
      height: 550px;
      display: flex;
      flex-wrap: wrap;
      width: 1330px;
      justify-content: flex-start;
    }
    .no-student {
      margin-bottom: 50px;
      padding-top: 50px;
      text-align: center;
      font-size: 16px;
      color: #999999;
      img {
        width: 483px;
        height: 304px;
      }
    }
    .more-box {
      width: 100%;
      padding-right: 60px;
      .more-btn {
        margin: 0 auto;
        width: 320px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        background: #00a4ff;
        border-radius: 25px;
        cursor: pointer;
      }
    }
  }
  .side {
    position: relative;
    div {
      background-size: 100% 100%;
    }
    .xsq {
      width: 102px;
      height: 247px;
      background-image: url("~@/assets/imgs/word2.png");
      // margin-left: 30px;
    }
    .bg1 {
      width: 139px;
      height: 268px;
      background-image: url("~@/assets/imgs/style1.png");
      position: absolute;
      left: 15px;
      top: 382px;
    }
    .bg2 {
      width: 80px;
      height: 155px;
      background-image: url("~@/assets/imgs/style2.png");
      position: absolute;
      left: 100px;
      top: 339px;
    }
  }
  .toTop {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #F3F3F3;
    border-radius: 6px;
    i {
      color: #333;
      font-size: 18px;
    }
  }
}
</style>
