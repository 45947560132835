<template>
  <ul class="discuss-container">
    <li v-for="(item, i) in discussList" :key="i">
      <img :src="item.url" alt="" />
      <div class="info">
        <h2 class="title">{{ item.title }}</h2>
        <p>{{ item.content }}</p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      discussList: [
        {
          url: require("@/assets/imgs/kyjl.png"),
          title: "分享见解",
          content: "与领域专家进行交流",
        },
        {
          url: require("@/assets/imgs/xsjh.png"),
          title: "聚合知识",
          content: "从知识和见解中获得价值",
        },
        {
          url: require("@/assets/imgs/xzcz.png"),
          title: "我的关注",
          content: "与您关注的学者建立连接",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.discuss-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1500px;
  li {
    display: flex;
    align-items: center;
    border-right: none;
    &:not(:last-child) {
      border-right: 1px solid #0092e0;
      padding-right: 130px;
    }
    img {
      width: 70px;
      height: 70px;
      margin-right: 28px;
    }
    .title {
      font-size: 39px;
      color: #00a4ff;
      font-weight: 400;
    }
    p {
      font-size: 22px;
      color: #333333;
    }
  }
}
</style>
