<template>
  <div class="round-container">
    <div class="side">
      <div class="bg1"></div>
      <div class="bg2"></div>
    </div>
    <h2>最新入驻学者</h2>
    <span class="icon-left" @click="turnPage('left')" v-if="curPage > 1"></span>
    <span class="icon--left" @click="turnPage('left')" v-else></span>
    <ul class="wrapper">
      <li v-for="(item, i) in autherList" :key="i">
        <div class="name" @click="toDetail(item)">{{ item.scholarName }}</div>
        <div class="school">{{ item.orgName }}</div>
      </li>
    </ul>
    <span
      class="icon-right"
      @click="turnPage('right')"
      v-if="curPage < pages"
    ></span>
    <span class="icon--right" @click="turnPage('right')" v-else></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      autherList: [
        {
          name: "曾碧卿",
          school: "华南师范大学",
        },
      ],
      curPage: 1,
      pages: 1,
    };
  },
  created() {
    this.latestScholar({
      pageNo: this.curPage,
      pageSize: 7,
    });
  },
  methods: {
    toDetail(item) {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: {
          id: item.userId,
        },
      });
      window.open(url.href, "_blank");
    },
    async latestScholar(data) {
      const resp = await this.$apis.mainServe.latestScholar(data);
      this.autherList = resp.data.list;
      this.pages = resp.data.pages;
    },
    turnPage(type) {
      if (type === "left") {
        if (this.curPage <= 1) {
          this.curPage = 1;
          return;
        }
        this.latestScholar({
          pageNo: --this.curPage,
          pageSize: 7,
        });
      } else {
        if (this.curPage >= this.pages) {
          this.curPage = this.pages;
          return;
        }
        this.latestScholar({
          pageNo: ++this.curPage,
          pageSize: 7,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.round-container {
  position: relative;
  margin: 80px 0;
  width: 1440px;
  height: 82px;
  background: #ffffff;
  border: 1px dotted #666666;;
  border-radius: 14px;
  display: flex;
  align-items: center;
  .wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 1259px;
    li {
      padding: 0 32px;
      border-right: 1px dashed #999999;
      &:last-child {
        border-right: none;
      }
      div {
        width: 115px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
      }
      .name {
        font-size: 20px;
        color: #333333;
        cursor: pointer;
        &:hover {
          color: #00a4ff;
        }
      }
      .school {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 15px;
        color: #999999;
      }
    }
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    margin: 0 20px;
    white-space: nowrap;
  }
  .icon-left,
  .icon-right,
  .icon--left,
  .icon--right {
    width: 10px;
    height: 30px;
    background-size: 100% 100%;
    margin: 0 10px;
    cursor: pointer;
  }
  .icon-left {
    background-image: url("~@/assets/imgs/left.png");
  }
  .icon-right {
    background-image: url("~@/assets/imgs/right.png");
  }
  .icon--left {
    background-image: url("~@/assets/qietu/right.png");
  }
  .icon--right {
    background-image: url("~@/assets/qietu/left.png");
  }
  
  .side {
    div {
      background-size: 100% 100%;
    }
    .bg1 {
      position: absolute;
      left: -85px;
      top: -121px;
      width: 114px;
      height: 220px;
      background-image: url("~@/assets/imgs/style3.png");
    }
    .bg2 {
      position: absolute;
      left: -108px;
      top: 0;
      width: 66px;
      height: 127px;
      background-image: url("~@/assets/imgs/style4.png");
    }
  }
}
</style>
