<template>
  <ul class="card-container">
    <div class="side">
      <div class="xsq"></div>
      <div class="bg1"></div>
      <div class="bg2"></div>
      <div class="bg1-right"></div>
      <div class="bg2-right"></div>
    </div>
    <li v-for="(item, i) in cardList" :key="i" class="item">
      <div class="top">
        <div class="title" @click="goDetails(item)">
          <p class="subject" v-if="item.circle.title">
            {{ item.circle.title }}
          </p>
          <p
            class="auther"
            v-if="item.circle.scholarNum && item.circle.postNum"
          >
            {{ item.circle.scholarNum }}位学者关注·{{
              item.circle.postNum
            }}个帖子
          </p>
        </div>
        <Button
          class="follow active"
          v-if="item.circle.followStatus"
          @click="onFocus(item.circle.id, true)"
          v-throttleClick="2000"
        >
          已关注
        </Button>
        <Button
          class="follow"
          v-else
          @click="onFocus(item.circle.id)"
          v-throttleClick="2000"
          >关注+</Button
        >
      </div>
      <div class="content" v-if="item.circle.detail">
        {{ item.circle.detail }}
      </div>
      <div class="line"></div>
      <ul class="info" v-if="item.discusses.length > 0">
        <li
          v-for="(v, index) in item.discusses"
          :key="index"
          :class="{ active: index === select }"
          @click="goDiscuss(v)"
        >
          <div>{{ v.title }}</div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      select: 0,
      cardList: [],
    };
  },
  created() {
    this.getFirstPage();
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  methods: {
    goDiscuss(v) {
      let url = this.$router.resolve({
        path: "/discussDetails",
        query: {
          id: v.id,
        },
      });
      window.open(url.href, "_blank");
    },
    async onFocus(id, status = false) {
      if (this.hasLogin) {
        // 1 关注 0 取消关注
        let data = {
          attentionId: id,
          type: status ? 0 : 1,
        };
        const resp = await this.$apis.circleServe.onFocus(data);
        if (resp.resultDesc === "成功!") {
          this.getFirstPage();
          // this.$Message.success(resp.resultDesc);
          this.$Message.success(status ? "取消关注成功" : "关注成功");
        } else {
          this.$Message.error("关注失败");
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    async getFirstPage() {
      const resp = await this.$apis.circleServe.firstPage();
      this.cardList = resp.data;
    },
    goDetails(item) {
      let url = this.$router.resolve({
        path: "/circledetails",
        query: {
          id: item.circle.id,
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  width: 1440px;
  min-height: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  .item {
    position: relative;
    padding: 30px;
    width: 624px;
    height: 350px;
    background: #ffffff;
    border: 1px dotted #999999;
    border-radius: 8px;
    margin-left: 41px;
    margin-bottom: 30px;
    &::before {
      position: absolute;
      left: 0;
      top: -1px;
      content: "";
      display: block;
      width: 622px;
      height: 4px;
      background-image: url("~@/assets/imgs/squre.png");
      background-size: 100% 100%;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        cursor: pointer;
        .subject {
          font-size: 26px;
          color: #00a4ff;
          width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .auther {
          font-size: 14px;
          color: #999999;
        }
      }
      .follow {
        width: 98px;
        height: 38px;
        background: #00a4ff;
        border-radius: 19px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        cursor: pointer;
        &.active {
          border: 1px solid #00a4ff;
          color: #00a4ff;
          background-color: transparent;
        }
      }
    }
    .content {
      width: 551px;
      margin: 25px 0 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 16px;
      color: #999999;
    }
    .line {
      margin-top: 25px;
      border-top: 1px dashed #999999;
    }
    .info {
      // border-top: 1px dashed #999999;
      li {
        list-style: disc;
        margin: 20px 0 0 15px;
        cursor: pointer;
        div {
          width: 557px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .side {
    div {
      background-size: 100% 100%;
    }
    .xsq {
      width: 102px;
      height: 248px;
      background-image: url("~@/assets/imgs/word1.png");
      position: absolute;
      left: 0;
      top: 0;
    }
    .bg1 {
      width: 114px;
      height: 238px;
      background-image: url("~@/assets/imgs/style3.png");
      position: absolute;
      left: 130px;
      top: 451px;
    }
    .bg2 {
      width: 84px;
      height: 162px;
      background-image: url("~@/assets/imgs/style4.png");
      position: absolute;
      left: 80px;
      top: 497px;
    }
    .bg1-right {
      width: 139px;
      height: 268px;
      background-image: url("~@/assets/imgs/style1.png");
      position: absolute;
      right: -80px;
      top: 0;
    }
    .bg2-right {
      width: 80px;
      height: 155px;
      background-image: url("~@/assets/imgs/style2.png");
      position: absolute;
      right: -110px;
      top: 160px;
    }
  }
}
</style>
