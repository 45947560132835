<template>
  <header id="header-container">
    <div class="top">
      <div class="sign">
        <a target="_blank" href="https://www.cnknowledge.com/index">
          <img style="cursor: pointer;width: 70px;margin-right: 20px;" v-lazy="require('@/assets/imgs/zwzsw.png')"
            alt="" />
        </a>
        <img @click="$router.go(0)" :src="require('@/assets/imgs/bjlogo.png')" alt="" />
      </div>
      <div class="select">
        <a @click="handleClick">发现学术圈</a>
        <a class="ying">应用<span></span></a>
        <div class="select-down">
          <a href="https://www.cnknowledge.com/" target="_blank">中文知识网</a>
          <a href="https://www.baikezaixian.com/" target="_blank">百科在线</a>
          <a href="http://www.tonghangpingyi.com/" target="_blank">同行评议</a>
        </div>
      </div>
      <div v-if="!token" class="user">
        <span @click="login(1)" class="login">登录</span>
        <span @click="login(0)" class="register">注册</span>
      </div>
      <div class="user" v-else>
        <Dropdown @on-click="userClick">
          <img v-if="userInfo.headPhoto" v-lazy="
            userInfo.headPhoto
              ? $global.imgUrl + userInfo.headPhoto
              : '@/assets/img/login/4.png'
          " alt="" />
          <img v-else src="@/assets/img/login/4.png" alt="" />
          <span class="name">{{
            userInfo.user.customerType === 1
            ? userInfo.account.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
            : userInfo.user.customerType === 2
              ? userInfo.name
              : userInfo.companyName
          }}</span>
          <DropdownMenu slot="list">
            <DropdownItem name="goUserCenter">用户中心</DropdownItem>
            <DropdownItem name="logOut">退出</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div class="logo"></div>
    <div class="search">
      <Input search placeholder="检索学者、学术圈、学术成果" @on-search="handleSearch" />
      <input type="text" class="elHidden" />
    </div>
    <Modal :scrollable="true" v-model="$store.state.alert" width="400px" class-name="my-center-modal" :footer-hide="true"
      @on-visible-change="visibleChange">
      <div class="content">
        <Top :sonUser="sonUser" @cahngeAct="cahngeAct" :act="act"></Top>
        <Contents :sonUser="sonUser" @cahngeAct="cahngeAct" @changeAlert="changeAlert" :act="act" :flag="flag"></Contents>
        <Footers @SetsonUser="SetsonUser" :sonUser="sonUser" :act="act"></Footers>
      </div>
    </Modal>
  </header>
</template>

<script>
import Top from "../components/top.vue";
import Contents from "../components/contents";
import Footers from "../components/footers";
import { mapState } from "vuex";
import { logOut } from "../../../apis/login";

export default {
  components: {
    Top,
    Contents,
    Footers,
  },
  computed: {
    ...mapState(["token", "userInfo"]),
  },
  data() {
    return {
      sonUser: 0,
      act: 1,
      flag: false,
      bai: `background-image: url("${this.$global.imgUrl}group1/M00/00/7E/rBF-YmGIhhmAN2UoAAYp3SjyRTQ896.png");`,
    };
  },
  methods: {
    visibleChange(e) {
      this.flag = e;
    },
    handleClick() {
      let url = this.$router.resolve({
        path: "/recommendcircle",
      });
      window.open(url.href, "_blank");
    },
    handleSearch(e) {
      if (!e) return;
      let url = this.$router.resolve({
        path: "search",
        query: {
          paramStr: e,
        },
      });
      window.open(url.href, "_blank");
    },
    userClick(e) {
      //e是事件名字直接调用
      this[e]();
    },
    async goUserCenter() {
      const res = await this.$apis.userServe.getUserInfo();
      this.$store.commit("loginInfo", {
        token: res.data.token,
        hasLogin: true,
        userInfo: res.content,
      });
      if (this.userInfo.user.customerType === 2) {
        let url = this.$router.resolve({
          path: "/user",
        });
        console.log(url);
        window.open(url.href);
      } else {
        let url = this.$router.resolve({
          path: "/user/attestation",
        });
        window.open(url.href);
      }
    },
    async logOut() {
      await logOut();
      this.$store.commit("logOut");

      // sessionStorage.setItem("token", "");
      this.$Message.success("退出成功");
      this.$router.go(0);
    },
    //login
    cahngeAct(e) {
      this.act = e;
    },
    changeAlert(e) {
      this.$store.commit("showAlert", e);
      // this.alert = e;
    },
    SetsonUser(e) {
      this.sonUser = e;
    },
    login(i) {
      console.log(1);
      this.act = i;
      // this.alert = true;
      this.$store.commit("showAlert", true);
    },
    //login
  },
};
</script>
<style lang="scss">
#header-container {
  .user {
    .ivu-select-dropdown {
      margin-top: 30px !important;
      margin-left: 30px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.my-center-modal {
  .ivu-modal-close {
    width: 50px;
    color: #fff !important;
    text-align: center;
    line-height: 50px;
    height: 50px;
    background: #00a4ff;
    border-radius: 0px 18px 0px 18px;

    .ivu-icon-ios-close {
      color: #fff;
    }
  }
}
</style>
<style lang="scss" scoped>
#header-container {
  height: 436px;
  // width: 100%;
  min-width: 1440px;
  padding-top: 50px;
  background-image: url("~@/assets/imgs/bgc.png");
  background-size: 100% 100%;
  margin: 0 auto;

  .top {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .sign {

      // margin: 0 -100px;
      img {
        width: 173px;
        height: 40px;
        margin-right: 11px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .select {
      width: 235px;
      height: 40px;
      background: #ffffff;
      opacity: 0.7;
      border-radius: 20px;
      text-align: center;
      font-size: 20px;
      line-height: 40px;
      cursor: pointer;
      position: relative;
      z-index: 99;

      a {
        color: #444444;
      }

      .ying {
        margin-left: 26px;
        display: inline-block;
        height: 100%;

        &:hover {
          &+.select-down {
            display: block;
            position: absolute;
            right: 0;
          }

          span {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-left: 4px;
            background-image: url("~@/assets/imgs/top.png");
            background-size: cover;
          }
        }

        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-left: 4px;
          background-image: url("~@/assets/imgs/xia.png");
          background-size: cover;
        }
      }

      .select-down {
        display: none;
        width: 99px;
        background-color: #fff;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 20px;
          height: 22px;
          background: #fff;
          position: absolute;
          right: 0;
          top: -22px;
        }

        a {
          display: block;
          white-space: nowrap;
          font-size: 14px;

          &:hover {
            background-color: #f3f3f3;
          }
        }

        &:hover {
          display: block;
          position: absolute;
          right: 0;
        }
      }

      &::v-deep .ivu-select-selection {
        border: none;
      }

      &::v-deep .ivu-select-selected-value {
        font-size: 20px;
      }
    }

    .user {
      font-size: 18px;
      color: #fefefe;
      cursor: pointer;
      white-space: nowrap;

      img {
        width: 30px;
        height: 30px;
        background: #f9f9f9;
        border: 1px solid #e6e6e6;
        border-radius: 50%;
        float: left;
      }

      .name {
        margin-top: 5px;
        margin-left: 8px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        display: block;
        float: left;
        color: #ffffff;
      }

      .login {
        margin-right: 20px;
      }

      .register {
        display: inline-block;
        text-align: center;
        line-height: 40px;
        width: 120px;
        height: 40px;
        background: #00a4ff;
        border-radius: 20px;
      }
    }
  }

  .logo {
    width: 615px;
    height: 84px;
    margin: 97px auto 109px;
    background-image: url("~@/assets/imgs/logoContent.png");
    background-size: 100% 100%;
  }

  .search {
    width: 840px;
    height: 70px;
    margin: 0 auto;

    &::v-deep .ivu-input {
      height: 70px;
      background-color: #fefefe;
      border: 1px solid #006db2;
      box-shadow: 0px 1px 8px 0px rgba(20, 122, 182, 0.4);
      border-radius: 35px;
      font-size: 18px;
      padding-left: 35px;
      padding-right: 70px;
    }

    &::v-deep .ivu-input-icon {
      font-size: 3em;
      line-height: 70px;
      right: 30px;
      color: #006db2;
    }

    .elHidden {
      width: 0;
      opacity: 0;
    }
  }
}
</style>
